import React, {Fragment, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import selectors from "./modules/appSelectors";
import actions from './modules/appActions';
import {useDispatch, useSelector} from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DEALER_PERMISSIONS} from "./Constants";
import Button from "@material-ui/core/Button";
import Icon from '@material-ui/core/Icon';
import AddBanner from "./containers/AddBanner";

const STATUS = ['', 'active', 'inactive'];

export default function Display(props) {
  const dispatch = useDispatch();
  const banners = useSelector(selectors.selectBanners);
  console.log('dealers ', banners)
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(banners)
  }, [banners, index])
  useEffect(() => {
    dispatch(actions.getBannersList())
  }, [])
  return (
    <Fragment>
      <Button
        color={'primary'}
        onClick={() => {
          setOpenDetail(true)
        }}
        variant="contained"
        style={{marginRight: 40, float: 'right'}}>
        Add
      </Button>
      <Table style={{marginTop: 30}} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Collection Name</TableCell>
            <TableCell>Collection Image</TableCell>
            <TableCell>Youtube Video Id</TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <TableCell
                  onClick={() => {
                    setSelectedOrder(row)
                    setOpenDetail(true)
                  }}>
                  {row.name}</TableCell>
                <TableCell><img src={row.image} style={{width: 30, height: 30}}/></TableCell>
                <TableCell>{row.youtubeid}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      dispatch(actions.deleteBanner(row.id))
                    }}
                  >
                    <Icon
                      fontSize={'large'} color="#000">delete_outline</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <AddBanner isOpen={openDetail} collection={selectedOrder} onClose={() => setOpenDetail(false)}/>
    </Fragment>
  );
}