import React, {Fragment, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddUser from "./containers/AddUser";
import LoginHistory from "./containers/LoginHistory";
import selectors from "./modules/appSelectors";
import actions from './modules/appActions';
import {useDispatch, useSelector} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {firebase} from "./firebase";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


export default function Users(props) {
  const dispatch = useDispatch();
  const dealers = useSelector(selectors.selectUsers);
  console.log('dealers ', dealers)
  const [openDetail, setOpenDetail] = useState(false);
  const [openLoginHistory, setOpenLoginHistory] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  useEffect(() => {
    const fUSer = firebase.auth().currentUser;
    const users = dealers.filter((user) => user.email === fUSer.email);
    if (users.length > 0) {
      setUser(users[0])
    }
    setData(dealers.filter((user) => user.permission !== 'manage'));
  }, [dealers, index])
  useEffect(() => {
    dispatch(actions.getUsersList())
  }, [])
  return (
    <Fragment>
      <Grid container xs={'auto'}>
      </Grid>
      {user && user.permission === 'manage' && <Button
        color={'primary'}
        onClick={() => {
          setSelectedOrder({})
          setOpenDetail(true)
        }}
        variant="contained"
        style={{marginRight: 40, float: 'right'}}>
        Add
      </Button>}
      <Table style={{marginTop: 30}} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Permissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id={`status-${index}`}
                    value={row.permission}
                    onChange={(e) => {
                      dispatch(actions.updateUserPermission(row.id, e.target.value))
                    }}
                  >
                    <MenuItem value={'stock'}>Stock</MenuItem>
                    <MenuItem value={'order'}>Order</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  {user.permission === 'manage' && <Button
                    variant="outlined"
                    onClick={() => {
                      dispatch(actions.deleteUser(row.id))
                    }}
                  >{'Delete'}</Button>}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <AddUser isOpen={openDetail} user={selectedOrder} onClose={() => setOpenDetail(false)}/>
    </Fragment>
  );
}