import React from "react";
export default function (props){
  return(
    <div style={{
      padding:10,
      margin:"auto",
      // position:'absolute',
      backgroundColor: props.bgColor,
      borderRadius: "50%",
      width:150,
      height:150,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      fontSize: 16
    }}>
      {props.text}
    </div>
  )
}