import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {mainListItems, orderListItems, stockListItems} from './listitems';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import Dealers from "./Dealers";
import Users from "./Users";
import Display from "./Display";
import {useDispatch, useSelector} from "react-redux";
import actions from './modules/appActions';
import LinearProgress from "@material-ui/core/LinearProgress";
import selectors from "./modules/appSelectors";
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import {firebase} from "./firebase";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function App(props) {
  console.log(' props ', props)
  const classes = useStyles();
  const dispatch = useDispatch();
  const users = useSelector(selectors.selectUsers);
  const loading = useSelector(selectors.selectLoading);
  const orders = useSelector(selectors.selectOrders);
  const alert = useSelector(selectors.selectAlert);
  const message = useSelector(selectors.selectAlertMessage);
  const [open, setOpen] = useState(!props.isMobile);
  const newCount = orders.filter((order) => 'new' === order.status).length
  const [user, setUser] = useState({});
  console.log(' new Count ', user);
  useEffect(() => {
    setOpen(!props.isMobile)
    dispatch(actions.listenUpdates())
  }, [props.isMobile])

  useEffect(() => {
    dispatch(actions.getUsersList())
  },[])
  useEffect(() => {
    const fUSer = firebase.auth().currentUser;
    const _users = users.filter((user) => user.email === fUSer.email);
    if (_users.length > 0) {
      setUser(_users[0])
    }
  }, [users])

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    dispatch(actions.updateAlert(false))
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline>
        </CssBaseline>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon/>
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Dashboard
            </Typography>
            <Link to={'/orders'}>
              <IconButton color="inherit">
                <Badge badgeContent={newCount} color="secondary">
                  <NotificationsIcon/>
                </Badge>
              </IconButton>
            </Link>
          </Toolbar>
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {message}
            </Alert>
          </Snackbar>
          <LinearProgress color="secondary" hidden={!loading}/>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon/>
            </IconButton>
          </div>
          <Divider/>
          {user && user.permission === 'manage' && <List>{mainListItems}</List>}
          {user && user.permission === 'order' && <List>{orderListItems}</List>}
          {user && user.permission === 'stock' && <List>{stockListItems}</List>}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route exact path="/dashboard" component={Dashboard}/>
              {user && (user.permission === 'manage' || user.permission === 'order') &&
              <Route exact path="/orders/:uid?" component={Orders}/>}
              {user && user.permission === 'manage' && <Route exact path="/dealers" component={Dealers}/>}
              {user && user.permission === 'manage' && <Route exact path="/users" component={Users}/>}
              {user && user.permission === 'manage' && <Route exact path="/display" component={Display}/>}
            </Switch>
          </Container>
        </main>
      </div>
    </Router>
  );
}