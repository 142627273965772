import React from 'react';
import ReactDOM from 'react-dom';
import Login from './Login';
import Splash from './Splash';
import Config from './config/app';
import {firebase, firestore} from './firebase';
import 'antd/dist/antd.css';
import './App.css'
import './index.css'
import App from "./App";
import {isMobile} from 'react-device-detect';
import {Provider} from 'react-redux';
import {configureStore, getHistory} from './modules/store';

const store = configureStore();

//Remove data fethced
var configReceived = !Config.remoteSetup;

checkLoginStatus();
displayApp();


//AUTHENTICATION
var loggedIn = false;

function checkLoginStatus() {

  if (Config.firebaseConfig.apiKey) {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        const snapshot = await firestore.collection('user').where('email','==',user.email).where('type','==','admin').get();
        if(snapshot.docs.length===0){
          await firebase.auth().signOut();
          return;
        }
        console.log("User is signed in " , user);

        if (Config.adminConfig.allowedUsers != null && Config.adminConfig.allowedUsers.indexOf(user.email) == -1) {
          //Error, this user is not allowed anyway
          alert("The user " + user.email + " doesn't have access to this admin panel!");
          firebase.auth().signOut();
        } else {
          loggedIn = true;
          //Config.navigation[3].path="users/"+user.uid;
          displayApp();
        }


      } else {
        // No user is signed in.
        console.log("No user is signed in ");
        loggedIn = false;
        displayApp();
        if (window.display) {
          window.display();
        }

      }
    })
  } else {
    // No user is signed in.
    console.log("No user is signed in, step 1 ");
    loggedIn = false;
    displayApp();
    if (window.display) {
      window.display();
    }
  }
}


function displayApp() {
  if (!configReceived) {
    ReactDOM.render(
      <Splash/>,
      document.getElementById('root')
    );
  } else {
    //Show splash window
    if (loggedIn) {
      ReactDOM.render(
        <Provider store={store}> <App isMobile={isMobile}/> </Provider>,
        document.getElementById('root')
      );
    } else {
      ReactDOM.render(
        <Login/>,
        document.getElementById('root')
      );
    }
  }

}

displayApp();
