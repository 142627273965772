import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import {Typography} from "antd";
import Grid from "@material-ui/core/Grid";
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import {useDispatch} from 'react-redux';
import actions from './../modules/appActions';

const {Title, Text} = Typography;

const Schema = Yup.object().shape({
  mode: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  trackingId: Yup.string().required('Required'),
  orderNumber: Yup.string().required('Required')
})

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    cellLabel: {
      textAlign: 'left',
      display: 'inline',
    },
  }),
);

export default function Tracking(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState(props.order);
  useEffect(() => {
    setOrder(props.order)
  }, [props.order])
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}>
        <Grid
          xs={8} md={6} lg={4}
          container
          justify={'center'}
          style={modalStyle}
          className={classes.paper}>
          <Grid xs={12} className={'vert-flex'}>
            <h3 id="simple-modal-title">{'Tracking Info'}</h3>
            <Formik
              onSubmit={(values) => {
                dispatch(actions.updateTrackingInfo(order.id, values, order))
                  .then(() => {
                    props.onClose()
                    const _order = {...order, tracking: values};
                    props.onUpdate(_order)
                  }, () => {
                  })
              }}
              validationSchema={Schema}
              initialValues={order.tracking ? order.tracking : {}}
            >
              {({submitForm, isSubmitting, isValid}) => (
                <Form>
                  <Grid container xs={12}>
                    <Grid xs={12}>
                      <Field
                        fullWidth={true}
                        component={TextField}
                        name="mode"
                        type="text"
                        label="Courier Mode"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        fullWidth={true}
                        component={TextField}
                        name="name"
                        type="text"
                        label="Courier Name"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        fullWidth={true}
                        component={TextField}
                        name="trackingId"
                        type="text"
                        label="Tracking ID"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        fullWidth={true}
                        component={TextField}
                        name="orderNumber"
                        type="text"
                        label="Order Number"
                      />
                    </Grid>
                  </Grid>
                  <div style={{float: 'right', marginTop: 20}}>
                    <Button
                      onClick={() => props.onClose()}
                      variant="contained"
                      style={{marginRight: 10}}>
                      Cancel
                    </Button>
                    <Button
                      disabled={!isValid}
                      onClick={submitForm}
                      variant="contained"
                      color="primary">
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}