import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment";
import OrderDetail from "./containers/OrderDetails";
import selectors from "./modules/appSelectors";
import actions from './modules/appActions';
import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import {useSelector, useDispatch, connect} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {ORDER_STATUSES} from "./Constants";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import {createStyles} from "@material-ui/styles";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tracking from "./containers/Tracking";
import LoginHistory from "./containers/LoginHistory";
import OrderHistory from "./containers/OrderHistory";


function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: 30
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

export default function Orders(props) {
  const dispatch = useDispatch();
  const orders = useSelector(selectors.selectOrders);
  const classes = useStyles();
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [term, setTerm] = useState('');
  const [counts, setCounts] = useState(['', '', '', '']);
  const [tracking, openTracking] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  useEffect(() => {
    const newCounts = [];
    ORDER_STATUSES.forEach((status) => {
      const count = orders.filter((order) => status === order.status).length
      newCounts.push(count)
    })
    newCounts.push(orders.length);
    setCounts(newCounts)
    if (index === 3 || index === -1) {
      console.log(' page ', page, orders);
      setData(orders);
      return;
    }
    const data = orders.filter((order) => ORDER_STATUSES[index] === order.status)
    setData(data)
  }, [orders, index])
  useEffect(() => {
    dispatch(actions.getOrderList({uid: props.match.params.uid}))
  }, [props.match.params.uid])
  return (
    <React.Fragment>
      <Grid container xs={12} sm={8} md={5} lg={4}>
        <Paper className={classes.root}>
          <InputBase
            value={term}
            onChange={(e) => {
              setTerm(e.target.value)
            }}
            className={classes.input}
            placeholder="Enter order number or dealer name"
            inputProps={{'aria-label': 'search'}}
            onKeyDown={(key) => {
              if (key.keyCode === 13) {
                dispatch(actions.searchOrders(term))
                setIndex(-1)
              }
            }}
          />
          <IconButton
            onClick={() => {
              dispatch(actions.searchOrders(term))
              setIndex(-1)
            }}
            type="submit"
            className={classes.iconButton}
            aria-label="search">
            <SearchIcon/>
          </IconButton>
        </Paper>
      </Grid>
      <Grid container xs={'auto'}>
        <Paper square>
          <Tabs value={index} onChange={(e, value) => {
            if (index === -1) {
              setTerm('')
              dispatch(actions.getOrderList())
            }
            setIndex(value)
            setPage(0)
          }} aria-label="simple tabs example">
            <Tab label={`New (${counts[0]})`}/>
            <Tab label={`Processing (${counts[1]})`}/>
            <Tab label={`Closed (${counts[2]})`}/>
            <Tab label={`All (${counts[3]})`}/>
          </Tabs>
        </Paper>
      </Grid>
      <Table style={{marginTop: 30}} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Order Number</TableCell>
            <TableCell>Dealer Name</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Tracking</TableCell>
            <TableCell>History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row) => (
            <TableRow key={row.orderNumber}>
              <TableCell
                onClick={() => {
                  setSelectedOrder(row)
                  setOpenDetail(true)
                }}>
                {row.invoiceId}</TableCell>
              <TableCell>{row.tracking ? row.tracking.orderNumber : ''}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id={`status-${index}`}
                  value={row.status}
                  onChange={(e) => {
                    dispatch(actions.updateOrderStatus(row.id, e.target.value))
                  }}
                >
                  {
                    ORDER_STATUSES.map((status) => (
                      <MenuItem value={status}>{status.toUpperCase()}</MenuItem>
                    ))
                  }
                </Select>
              </TableCell>
              <TableCell>
                {row.tracking ?
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSelectedOrder(row)
                      openTracking(true)
                    }}
                  >{'View Tracking'}</Button> :
                  <Button
                    variant="outlined"
                    color={'primary'}
                    onClick={() => {
                      setSelectedOrder(row)
                      openTracking(true)
                    }}>Add Tracking</Button>}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedOrder(row)
                    setOpenHistory(true)
                  }}
                >{'View history'}</Button>
              </TableCell>
            </TableRow>
          ))}
          {data.length === 0 &&
          <TableRow key={'empty'}>
            <TableCell colSpan={4}>
              <Typography style={{textAlign: 'center'}} variant="h4" component="h4">{'No data found'}</Typography>
            </TableCell>
          </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={12}
              count={data.length}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
              page={page}
              onChangePage={(e, newPage) => setPage(newPage)}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <OrderDetail isOpen={openDetail} order={selectedOrder} onClose={() => setOpenDetail(false)}/>
      <OrderHistory isOpen={openHistory} order={selectedOrder} onClose={() => setOpenHistory(false)}/>
      <Tracking
        open={tracking}
        onClose={()=>openTracking(false)}
        order={selectedOrder}
        onUpdate={(order)=>{}}
      />
    </React.Fragment>
  );
}