import React, {useEffect, useState} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import {Col, Row, Container} from "react-bootstrap";
import {Typography} from "antd";
import Grid from "@material-ui/core/Grid";
import * as Yup from 'yup';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {useSelector, useDispatch, connect} from 'react-redux';
import actions from './../modules/appActions';
import Tracking from "./Tracking";

const {Title, Text} = Typography;

const Schema = Yup.object().shape({
  mode: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  trackingId: Yup.string().required('Required')
})

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    cellLabel: {
      textAlign: 'left',
      display: 'inline',
    },
  }),
);

export default function OrderDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [tracking, openTracking] = useState(false);
  useEffect(() => {
    setOrder(props.order)
  }, [props.order])
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const OrderRow = ({label, value, name}) => {
    return (
      <Grid xs={12} container alignItems={'center'} justify={"space-between"} style={{marginTop: 10}}>
        <Grid sm={5}>
          <Title className={classes.cellLabel} level={5}>{label}</Title>
        </Grid>
        <Grid sm={7} style={{paddingRight: 20}}>
          <Field
            fullWidth={true}
            component={TextField}
            name={name}
            type="text"
            label={''}
          />
        </Grid>
      </Grid>
    )
  }
  const body = (
    <Formik
      onSubmit={(values) => {
        dispatch(actions.updateOrder(order.id, values))
          .then(() => {
            props.onClose()
          }, () => {
          })
      }}
      initialValues={order}
    >
      {({submitForm, isSubmitting, isValid}) => (
        <Form>
          <Grid
            xs={10} md={8} lg={6}
            container
            justify={'center'}
            style={modalStyle}
            className={classes.paper}>
            <Grid xs={12} className={'vert-flex'}>
              <h2 id="simple-modal-title">{'Order Details'}</h2>
              <Grid xs={12} container>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Order No'} value={order.orderNumber} name={'orderNumber'}/>
                </Grid>
                <Grid xs={12} sm={6}>
                  <OrderRow
                    label={'Order Date'}
                    value={moment(order.timestamp).format('DD MMM, yyyy hh:mm a')}
                    name={'timestamp'}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Name'} value={order.name} name={'name'}/>
                </Grid>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Address'} value={order.address} name={'address'}/>
                </Grid>
              </Grid>

              <Grid xs={12} container>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Courier Mode'} value={order.courierMode} name={'courierMode'}/>
                </Grid>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Courier Name'} value={order.courierName} name={'courierName'}/>
                </Grid>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={12} sm={6}>
                  <Grid xs={12} container alignItems={'center'} justify={"space-between"} style={{marginTop: 10}}>
                    <Grid sm={6}>
                      <Title className={classes.cellLabel} level={5}>{'Tracking'}</Title>
                    </Grid>
                    <Grid sm={6}>
                      {order.tracking ?
                        <Button
                          name={'viewtracking'}
                          variant="contained"
                          onClick={() => {
                            openTracking(true)
                          }}
                        >{'View Tracking'}</Button> :
                        <Button
                          name={'addTracking'}
                          variant="contained"
                          color={'primary'}
                          onClick={() => {
                            openTracking(true)
                          }}>Add Tracking</Button>}

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Table size="small" style={{marginTop: 30}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Design no</TableCell>
                    <TableCell>Design Name</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.stocks && order.stocks.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.stock.Code}</TableCell>
                      <TableCell>{row.stock.Name}</TableCell>
                      <TableCell>
                        <Field
                          type="number"
                          component={TextField}
                          name={`stocks[${index}].quantity`}
                          label={''}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{float: 'right', marginTop: 20}}>
                <Button
                  onClick={() => props.onClose()}
                  variant="contained"
                  style={{marginRight: 10}}>
                  Cancel
                </Button>
                <Button
                  color={'primary'}
                  onClick={()=>{submitForm()}}
                  variant="contained"
                  style={{marginRight: 10}}>
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => {
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Tracking
        open={tracking}
        onClose={() => openTracking(false)}
        order={order}
        onUpdate={(order) => {
          setOrder(order)
        }}
      />
    </div>
  );
}