import XLSX from "xlsx";
import {uploadToFirestore} from "../firebaseService";

const readFile = async (file, onComplete, onError)=> {
  const reader = new FileReader();
  reader.onload = async (evt) => { // evt = on_file_select event
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, {type: 'binary'});
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, {header: 1});

    await uploadToFirestore(data, onComplete, onError)
  };
  reader.readAsBinaryString(file);
}
export {readFile}