//FireBase
exports.firebaseConfig = {
  apiKey: "AIzaSyBPAl0bfMuxrAxmhB6v2HDhhqurqS5M_1c",
  authDomain: "stockwall.firebaseapp.com",
  databaseURL: "https://wallking-hosting-default-rtdb.firebaseio.com",
  projectId: "wallking-hosting",
  storageBucket: "wallking-hosting.appspot.com",
  messagingSenderId: "582707423899",
  appId: "1:582707423899:web:a7e41581b6f6b0be7f743e"

  /*apiKey: "AIzaSyDgKbePq9wTBGSrYhpvsWObqYrd6QJxxQk",
  authDomain: "stockwall-local.firebaseapp.com",
  databaseURL: "https://stockwall-local-default-rtdb.firebaseio.com",
  projectId: "stockwall-local",
  storageBucket: "stockwall-local.appspot.com",
  messagingSenderId: "1086524123502",
  appId: "1:1086524123502:web:f6b8a49153c6fe3153397f"*/

};

//App setup
exports.adminConfig = {
  "appName": "StockWall Admin",
  "slogan": "made with love for a better firebase.",
  "email": 'gautam@iwiz.in'
}
/*exports.API_BASE_URL = 'http://localhost:5001/wallking-hosting/us-central1/'*/
exports.API_BASE_URL = 'https://us-central1-wallking-hosting.cloudfunctions.net/';

exports.userDetails = {}
