import actions from './appActions';

const initialData = {
  orders: [],
  dealers: {},
  stocks:[],
  users:[],
  banners:[],
  loading: false,
  alert: false,
  alertMessage: '',
  errorMessage: null,
  user: {},
};

export default (state = initialData, {type, payload}) => {

  if (type === actions.ORDERS_LIST) {
    return {
      ...state,
      loading: false,
      orders: [...payload.orders]
    };
  }
  else if (type === actions.STOCKS_LIST) {
    return {
      ...state,
      loading: false,
      stocks: [...payload.stocks]
    };
  }
  else if (type === actions.DEALERS_LIST) {
    return {
      ...state,
      loading: false,
      dealers: payload.dealers
    };
  }
  else if (type === actions.CURRENT_USER) {
    return {
      ...state,
      loading: false,
      user: payload.user
    };
  }
  else if (type === actions.BANNERS_LIST) {
    return {
      ...state,
      loading: false,
      banners: payload.banners
    };
  }
  else if (type === actions.USERS_LIST) {
    return {
      ...state,
      loading: false,
      users: payload.users
    };
  }
  else if (type === actions.ORDER_UPDATED) {
    let _orders = [...state.orders]
    _orders = _orders.map(order => order.id === payload.order.id ? payload.order : order);
    return {
      ...state,
      orders: _orders,
    };
  } else if (type === actions.ORDER_ADDED) {
    if(payload === undefined){
      return state
    }
    const _orders = [...state.orders]
    const _order = _orders.find(o => o.id === payload.order.id);
    if (!_order)
      return {
        ...state,
        orders: [payload.order,...state.orders],
      }
    else
      return state
  } else if (type === actions.LOADING) {
    return {
      ...state,
      loading: payload.loading,
    };
  }
  else if (type === actions.ALERT) {
    return {
      ...state,
      alert: payload.alert,
      alertMessage: payload.message,
    };
  }
  return state;
}
