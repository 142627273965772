import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import {Typography} from "antd";
import Grid from "@material-ui/core/Grid";
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import {useDispatch} from 'react-redux';
import actions from './../modules/appActions';
import Tracking from "./Tracking";

const {Title, Text} = Typography;

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required').matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
})

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    cellLabel: {
      textAlign: 'left',
      display: 'inline',
    },
  }),
);

export default function AddUSer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [tracking, openTracking] = useState(false);
  useEffect(() => {
    setOrder(props.user)
  }, [props.user])
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const OrderRow = ({label, value, name}) => {
    return (
      <Grid xs={12} container alignItems={'center'} justify={"space-between"} style={{marginTop: 10}}>
        <Grid sm={5}>
          <Title className={classes.cellLabel} level={5}>{label}</Title>
        </Grid>
        <Grid sm={7} style={{paddingRight: 20}}>
          <Field
            fullWidth={true}
            component={TextField}
            name={name}
            type="text"
            label={''}
          />
        </Grid>
      </Grid>
    )
  }
  const body = (
    <Formik
      onSubmit={(values) => {
        dispatch(actions.addUser(values))
          .then(() => {
            props.onClose()
          }, () => {
          })
      }}
      validationSchema={Schema}
      initialValues={order}
    >
      {({submitForm, isSubmitting, isValid}) => (
        <Form>
          <Grid
            xs={10} md={8} lg={6}
            container
            justify={'center'}
            style={modalStyle}
            className={classes.paper}>
            <Grid xs={12} className={'vert-flex'}>
              <h2 id="simple-modal-title">{'Add User'}</h2>
              <Grid xs={12} container>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Name'} value={order.name} name={'name'}/>
                </Grid>
                <Grid xs={12} sm={6}>
                  <OrderRow label={'Email'} value={order.email} name={'email'}/>
                </Grid>
              </Grid>

              <div style={{float: 'right', marginTop: 20}}>
                <Button
                  onClick={() => props.onClose()}
                  variant="contained"
                  style={{marginRight: 10}}>
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  color={'primary'}
                  onClick={()=>{submitForm()}}
                  variant="contained"
                  style={{marginRight: 10}}>
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => {
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Tracking
        open={tracking}
        onClose={() => openTracking(false)}
        order={order}
        onUpdate={(order) => {
          setOrder(order)
        }}
      />
    </div>
  );
}