import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Space, Typography} from "antd";
import {useDropzone} from "react-dropzone";
import {getAllDocuments} from "./services/firebaseService";
import {useSelector, useDispatch, connect} from 'react-redux';
import moment from "moment";
import {readFile} from "./services/xlsx/importer";
import Paper from "@material-ui/core/Paper";
import actions from "./modules/appActions";
import selectors from "./modules/appSelectors";
import Circle from "./components/Circle";
import {firebase} from "./firebase";

const {Title, Text} = Typography;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginTop: 20
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const stocks = useSelector(selectors.selectStocks);
  const orders = useSelector(selectors.selectOrders);
  const users = useSelector(selectors.selectUsers);
  const [documentCount, setDocumentCount] = useState(0);
  const [newOrders, setNewOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [user, setUser] = useState({});
  const [date, setDate] = useState('');
  const dispatch = useDispatch();
  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    dispatch(actions.displayLoading())
    readFile(file,
      (message) => {
        dispatch(actions.updateAlert(true, message))
        dispatch(actions.getStocksList())
      },
      () => {
        alert(e)
      }
    )
  }, [])
  useEffect(() => {
    dispatch(actions.getStocksList())
    dispatch(actions.getUsersList())
  }, [])
  useEffect(() => {
    const fUSer = firebase.auth().currentUser;
    const _users = users.filter((user) => user.email === fUSer.email);
    if (_users.length > 0) {
      setUser(_users[0])
    }
  }, [users])
  useEffect(() => {
    const newCount = orders.filter((order) => 'new' === order.status).length
    setNewOrders(newCount);
    const processingCount = orders.filter((order) => 'processing' === order.status).length
    setPendingOrders(processingCount);
  }, [orders])
  useEffect(() => {
    updateMetaData(stocks)
  }, [stocks])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const updateMetaData = async (stocks) => {
    setDocumentCount(stocks.length);
    let quantity = 0;
    if (stocks.length > 0) {
      const date = stocks[0].timestamp;
      setDate(moment(date).format('DD MMM YYYY hh:mm a'))
      stocks.forEach((doc) => {
        quantity += doc['Balance Qty']
      })
      setQuantity(quantity)
    }
  }
  return (
    <>
      <Grid container justify={'center'}>
        <Grid xs={12} sm={8} md={6}>
          <Grid item xs={12}>
            <Paper className={classes.paper} {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="space-align-container">
                <div className="space-align-block">
                  <Space align="center">
                    {
                      isDragActive ?
                        <span>Drop the file here ...</span> :
                        <span>Drag 'n' drop file here or </span>
                    }
                    <Button>
                      <UploadOutlined/> Click to Upload
                    </Button>
                  </Space>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div className={'flex-horiz'}>
                <Title level={5}>{'No of items'}</Title>
                <Text>{documentCount}</Text>
              </div>
              <div className={'flex-horiz'}>
                <Title level={5}>{'Qty'}</Title>
                <Text>{quantity ? quantity.toFixed(2) : '0'}</Text>
              </div>
              <div className={'flex-horiz'}>
                <Title level={5}>{'Uploaded at'}</Title>
                <Text>{date}</Text>
              </div>
            </Paper>
          </Grid>
          {
            (user.permission === 'manage' || user.permission === 'order') && <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid xs={12} container justify={'center'}>
                  <Grid xs={12} sm={6} justify={'center'} alignItems={'center'}>
                    <Circle bgColor={'#75cfb8'} text={newOrders}/>
                    <Title level={5} style={{textAlign: 'center', marginTop: 20}}>{'New'}</Title>
                  </Grid>
                  <Grid xs={12} sm={6} justify={'center'} alignItems={'center'}>
                    <Circle bgColor={'#f5d782'} text={pendingOrders}/>
                    <Title level={5} style={{textAlign: 'center', marginTop: 20}}>{'Processing'}</Title>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
}