import {firebase, firestore} from './../firebase';
import actions from './../modules/appActions';
import config from './../config/app'
import axios from "axios";

const uploadToFirestore = async (data, onComplete, onError) => {
  try {
    await deleteCollection('stock');
    const stocks = [];
    //await firestore.collection('metadata').doc('data').delete();
    setTimeout(async () => {
      const keys = ['Code', 'Name', 'Balance Qty']
      delete data[0];
      data.forEach((doc, index) => {
        const rec = {timestamp: Date.now()};
        keys.forEach((key, index) => {
          rec[key] = doc[index]
        })
        if (rec['Code'] !== undefined && rec['Name'] !== undefined && rec['Balance Qty'] !== undefined && !Number.isNaN(parseFloat(rec['Balance Qty'])))
          stocks.push(rec)
        else {
          console.log(`rec ${index} `, rec)
        }
      })
      console.log(' ON Complete ')
      const stockCollection = await firestore.collection('metadata').doc('data').collection("stocks");
      let i, j, chunk = 1000;
      for (i = 0, j = stocks.length; i < j; i += chunk) {
        const temporary = stocks.slice(i, i + chunk);
        await stockCollection.add({timestamp: Date.now(), stocks: temporary})
      }
      await firestore.collection('metadata').doc('sync').set({uploadDate: Date.now()});
      onComplete('Successfully uploaded to server ')
    }, 1000)
  } catch (e) {
    console.log(e)
    onError(e)
  }
}

const deleteCollection = async (path) => {
  const ref = await firestore.collection('metadata').doc('data').collection('stocks')
  ref.where("timestamp", "<", Date.now())
    .get().then(function (querySnapshote) {
    querySnapshote.forEach(element => {
      element.ref.delete();
    });
  })
}
const getAllDocuments = async () => {
  try {
    const snapshot = await firestore.collection('metadata').doc('data').collection('stocks').get();
    let stocks = []
    snapshot.docs.forEach(doc => {
      console.log('data ', doc.data)
      stocks = [...doc.data().stocks, ...stocks]
    });
    return stocks;
  } catch (e) {
    console.log(e)
    return [];
  }
}
const getAllUsers = async () => {
  const snapshot = await firestore.collection('user').where('type', '==', 'admin').get();
  return snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
}
const getAllBanners = async () => {
  const snapshot = await firestore.collection('banner').get()
  return snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
}
const getAllOrders = async (filters = {}) => {
  try {
    const uid = filters && filters.uid ? filters.uid : null
    let ref = firestore.collection('order');
    console.log(' uid ', uid !== null)
    if (uid !== null)
      ref = ref.where('uid', '==', uid)
    console.log(' uid ', ref)
    const snapshot = await ref.orderBy("timestamp", "desc").get()
    return snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
  } catch (e) {
    console.log(e)
  }
}
const searchOrders = async (term) => {
  try {
    const snapshot = await firestore.collection('order').orderBy("timestamp", "desc").get()

    const orders = await snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    const matched = [];
    orders.forEach((order) => {
      console.log('tracking')
      if (order.tracking && order.tracking.orderNumber && order.tracking.orderNumber.includes(term)) {
        matched.push(order)
      } else if (order.name && order.name.toLowerCase().includes(term)) {
        matched.push(order)
      }
    })
    console.log(' matched ', matched);
    return matched;
  } catch (e) {
    console.log(e);
    return [];
  }

}
const getAllDealers = async () => {
  const orders = await getAllOrders();
  console.log(' orders  ', orders);
  const snapshot = await firestore.collection('user').where('type', '!=', 'admin').get()
  const dealers = {};
  snapshot.docs.forEach(doc => {
    const data = doc.data();
    dealers [data.uid] = {id: doc.id, orders: 0, ...doc.data()}
  });
  orders.forEach((order) => {
    if (dealers[order.uid]) {
      const dealer = dealers[order.uid];
      dealers[order.uid] = {...dealer, orders: dealer.orders + 1}
    }
  })
  return dealers;
}
export const updateTrackingInfo = async (id, trackingInfo, order) => {
  try {
    if(order['tracking'])
      order['history'] = [{timestamp: Date.now(), event: `Tracking Updated`, by: `admin(${firebase.auth().currentUser.email})`}, ...order.history]
    else
      order['history'] = [{timestamp: Date.now(), event: `Order Dispatched`, by: `admin(${firebase.auth().currentUser.email})`}, ...order.history]
    order['tracking'] = trackingInfo;
    await firestore.collection('order').doc(id).update(order)
  } catch (e) {
    console.log(' error ', e)
  }
}
export const updateOrder = async (id, order) => {
  try {
    let quantity = 0;
    order.stocks.forEach((stock) => {
      quantity += parseFloat(stock.quantity);
    });
    order['history'] = [{timestamp: Date.now(), event: `Order Updated`, by: `admin(${firebase.auth().currentUser.displayName})`}, ...order.history]
    const result = await firestore.collection('order').doc(id).update({...order, quantity})
    console.log(' result ', result);
  } catch (e) {
    console.log(' error ', e)
  }
}
export const addUpdateDocument = async (collection, doc) => {
  try {
    if (doc.id)
      await firestore.collection(collection).doc(doc.id).set(doc)
    else
      await firestore.collection(collection).add(doc)
  } catch (e) {
    console.log(' error ', e)
  }
}
export const deleteDocument = async (collection, id) => {
  try {
    await firestore.collection(collection).doc(id).delete()
  } catch (e) {
    console.log(' error ', e)
  }
}
export const updateDealerStatus = async (id, status) => {
  try {
    const result = await firestore.collection('user').doc(id).update({status: status})
    console.log(' result ', result);
  } catch (e) {
    console.log(' error ', e)
  }
}
export const updateUserStatus = async (id, permission) => {
  try {
    const result = await firestore.collection('user').doc(id).update({permission: permission})
    console.log(' result ', result);
  } catch (e) {
    console.log(' error ', e)
  }
}
export const updateDealer = async (id, dealer) => {
  try {
    if (id)
      await firestore.collection('user').doc(id).set(dealer)
    else {
      let snapshot = await firestore.collection('user').where('email', '==', dealer.email).get()
      if (snapshot.docs.length > 0) {
        return {
          error: true,
          message: 'duplicate email'
        }
      }
      snapshot = await firestore.collection('user').where('mobile', '==', dealer.mobile).get()
      if (snapshot.docs.length > 0) {
        return {
          error: true,
          message: 'duplicate phone number'
        }
      }
      const password = `${Math.floor(100000 + Math.random() * 900000)}`
      //await firebase.auth().createUserWithEmailAndPassword(dealer.email,password+'')
      dealer['password'] = password;
      dealer['status'] = 'active';
      dealer['type'] = 'dealer';
      dealer['permission'] = 'stock';
      const response = await axios.post(`${config.API_BASE_URL}createUser`, {user: {...dealer, password}});
      console.log(' response ', response.data);
    }
    return {
      error: false,
    }
  } catch (e) {
    console.log(' error ', e)
    return {
      error: true,
      message: 'Error on add/update dealer'
    }
  }
}
export const addUser = async (user) => {
  try {
    let snapshot = await firestore.collection('user').where('email', '==', user.email).get()
    if (snapshot.docs.length > 0) {
      return {
        error: true,
        message: 'duplicate email'
      }
    }
    const password = `${Math.floor(100000 + Math.random() * 900000)}`
    user['password'] = password;
    user['type'] = 'admin';
    user['permission'] = 'stock';
    const response = await axios.post(`${config.API_BASE_URL}createUser`, {user: {...user}});
    return {
      error: false,
    }
  } catch (e) {
    console.log(' error ', e)
    return {
      error: true,
      message: 'Error on add/update dealer'
    }
  }
}
export const updateOrderStatus = async (id, status) => {
  try {
    await firestore.collection('order').doc(id).update({status: status})
    const snapshot = await firestore.collection('order').doc(id).get();
    const order = snapshot.data();
    let event = 'Order Processed';
    if(status==='closed'){
      event = 'Order Closed';
    }
    order['history'] = [{timestamp: Date.now(), event, by: `admin(${firebase.auth().currentUser.displayName})`}, ...order.history]
    await firestore.collection('order').doc(id).update(order)
  } catch (e) {
    console.log(' error ', e)
  }
}
const logout = async () => {
  try {
    const snapshot = await firebase.auth().signOut();
  } catch (e) {
    console.log(e)
  }
}
const updateListener = async (dispatch) => {
  firestore.collection("order")
    .onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "added") {
          dispatch({
            type: actions.ORDER_ADDED,
            payload: {order: {id: change.doc.id, ...change.doc.data()}}
          })
        }
        if (change.type === "modified") {
          console.log("Modified city: ", change.doc.data());
          dispatch({
            type: actions.ORDER_UPDATED,
            payload: {order: {id: change.doc.id, ...change.doc.data()}}
          })
        }
        if (change.type === "removed") {
          console.log("Removed city: ", change.doc.data());
        }
      });
    });
}
export {
  uploadToFirestore,
  deleteCollection,
  getAllDocuments,
  getAllOrders,
  logout,
  updateListener,
  getAllDealers,
  searchOrders,
  getAllBanners,
  getAllUsers
}
