import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AirplayIcon from '@material-ui/icons/Airplay';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {Link} from 'react-router-dom';
import {logout} from "./services/firebaseService";

export const mainListItems = (
  <div>
    <Link to={'/dashboard'}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon/>
        </ListItemIcon>
        <ListItemText primary="Dashboard"/>
      </ListItem>
    </Link>
    <Link to={'/orders'}>
      <ListItem button>
        <ListItemIcon>
          <ShoppingCartIcon/>
        </ListItemIcon>
        <ListItemText primary="Orders"/>
      </ListItem>
    </Link>
    <Link to={'/dealers'}>
      <ListItem button>
        <ListItemIcon>
          <PeopleOutlineOutlinedIcon/>
        </ListItemIcon>
        <ListItemText primary="Dealers"/>
      </ListItem>
    </Link>
    <Link to={'/users'}>
      <ListItem button>
        <ListItemIcon>
          <GroupWorkIcon/>
        </ListItemIcon>
        <ListItemText primary="Users"/>
      </ListItem>
    </Link>
    <Link to={'/display'}>
      <ListItem button>
        <ListItemIcon>
          <AirplayIcon/>
        </ListItemIcon>
        <ListItemText primary="Display"/>
      </ListItem>
    </Link>
    <ListItem
      button
      onClick={() => {
        logout()
      }}>
      <ListItemIcon>
        <ExitToAppOutlinedIcon/>
      </ListItemIcon>
      <ListItemText
        primary="Logout"/>
    </ListItem>
  </div>
);

export const stockListItems = (
  <div>
    <Link to={'/dashboard'}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon/>
        </ListItemIcon>
        <ListItemText primary="Dashboard"/>
      </ListItem>
    </Link>
    <ListItem
      button
      onClick={() => {
        logout()
      }}>
      <ListItemIcon>
        <ExitToAppOutlinedIcon/>
      </ListItemIcon>
      <ListItemText
        primary="Logout"/>
    </ListItem>
  </div>
);
export const orderListItems = (
  <div>
    <Link to={'/dashboard'}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon/>
        </ListItemIcon>
        <ListItemText primary="Dashboard"/>
      </ListItem>
    </Link>
    <Link to={'/orders'}>
      <ListItem button>
        <ListItemIcon>
          <ShoppingCartIcon/>
        </ListItemIcon>
        <ListItemText primary="Orders"/>
      </ListItem>
    </Link>
    <ListItem
      button
      onClick={() => {
        logout()
      }}>
      <ListItemIcon>
        <ExitToAppOutlinedIcon/>
      </ListItemIcon>
      <ListItemText
        primary="Logout"/>
    </ListItem>
  </div>
);