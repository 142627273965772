import React, {useEffect, useState, Fragment} from 'react';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment";
import DealerDetail from "./containers/DealerDetails";
import LoginHistory from "./containers/LoginHistory";
import selectors from "./modules/appSelectors";
import actions from './modules/appActions';
import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import {useSelector, useDispatch, connect} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DEALER_PERMISSIONS, ORDER_STATUSES} from "./Constants";
import Button from "@material-ui/core/Button";

const STATUS = ['', 'active', 'inactive'];

export default function Dealers(props) {
  const dispatch = useDispatch();
  const dealers = useSelector(selectors.selectDealers);
  console.log('dealers ', dealers)
  const [openDetail, setOpenDetail] = useState(false);
  const [openLoginHistory, setOpenLoginHistory] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log(' index', index)
    if (index === 0) {
      setData(Object.keys(dealers).map((key) => dealers[key]));
      return;
    }
    const keys = Object.keys(dealers).filter((key) => dealers[key].status === STATUS[index])
    setData(keys.map((key) => dealers[key]));
  }, [dealers, index])
  useEffect(() => {
    dispatch(actions.getDealersList())
  }, [])
  return (
    <Fragment>
      <Grid container xs={'auto'}>
        <Paper square>
          <Tabs value={index} onChange={(e, value) => {
            setIndex(value)
          }} aria-label="simple tabs example">
            <Tab label="All"/>
            <Tab label="Active"/>
            <Tab label="Inactive"/>
          </Tabs>
        </Paper>
      </Grid>
      <Button
        color={'primary'}
        onClick={() => {
          setSelectedOrder({})
          setOpenDetail(true)
        }}
        variant="contained"
        style={{marginRight: 40, float: 'right'}}>
        Add
      </Button>
      <Table style={{marginTop: 30}} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Dealer Name</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Orders</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Permissions</TableCell>
            <TableCell>Login History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <TableCell
                  onClick={() => {
                    setSelectedOrder(row)
                    setOpenDetail(true)
                  }}>
                  {row.name}</TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell
                  onClick={() => {
                    props.history.push(`/orders/${row.uid}`)
                  }}
                >{row.orders}</TableCell>
                <TableCell>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id={`status-${index}`}
                    value={row.status}
                    onChange={(e) => {
                      dispatch(actions.updateDealerStatus(row.id, e.target.value))
                    }}
                  >
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'inactive'}>Inactive</MenuItem>
                    <MenuItem value={'suspended'}>Suspended</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id={`permission-${index}`}
                    value={row.permission}
                    onChange={(e) => {
                      dispatch(actions.updateDealer(row.id, {...row, permission: e.target.value}))
                    }}
                  >
                    {
                      DEALER_PERMISSIONS.map((permission) => (
                        <MenuItem value={permission}>{permission.toUpperCase()}</MenuItem>
                      ))
                    }
                  </Select>
                </TableCell>
                <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setSelectedOrder(row)
                        setOpenLoginHistory(true)
                      }}
                    >{'View history'}</Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <DealerDetail isOpen={openDetail} order={selectedOrder} onClose={() => setOpenDetail(false)}/>
      <LoginHistory isOpen={openLoginHistory} order={selectedOrder} onClose={() => setOpenLoginHistory(false)}/>
    </Fragment>
  );
}