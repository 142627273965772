import {
  addUpdateDocument, addUser, deleteDocument,
  getAllBanners,
  getAllDealers,
  getAllDocuments,
  getAllOrders, getAllUsers,
  searchOrders,
  updateDealer,
  updateDealerStatus,
  updateListener,
  updateOrder,
  updateOrderStatus,
  updateTrackingInfo, updateUserStatus
} from "../services/firebaseService";

const prefix = 'APP';

const actions = {
  CURRENT_USER: `${prefix}_CURRENT_USER`,
  STOCKS_LIST: `${prefix}_STOCKS_LIST`,
  ORDERS_LIST: `${prefix}_ORDERS_LIST`,
  DEALERS_LIST: `${prefix}_DEALERS_LIST`,
  USERS_LIST: `${prefix}_USERS_LIST`,
  BANNERS_LIST: `${prefix}_BANNERS_LIST`,
  ORDER_UPDATED: `${prefix}_ORDER_UPDATED`,
  ORDER_ADDED: `${prefix}_ORDER_ADDED`,
  LOADING: `${prefix}_loading`,
  ALERT: `${prefix}_alert`,
  ERROR: `${prefix}_ERROR`,

  displayLoading: () => async (dispatch) => {
    dispatch({
      type: actions.LOADING,
      payload: {loading: true}
    });
  },
  updateAlert: (visible, message) => async (dispatch) => {
    dispatch({
      type: actions.ALERT,
      payload: {alert: visible, message}
    });
  },
  getOrderList: (filters = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: {loading: true}
      });
      const orders = await getAllOrders(filters);
      console.log('getOrderList ', orders)

      dispatch({
        type: actions.ORDERS_LIST,
        payload: {orders}
      });
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: {message: error}
      });
    }
  },
  getStocksList: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: {loading: true}
      });
      const stocks = await getAllDocuments();
      console.log('Stocks', stocks)
      dispatch({
        type: actions.STOCKS_LIST,
        payload: {stocks}
      });
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: {message: error}
      });
    }
  },
  searchOrders: (term) => async (dispatch) => {
    if (term === '') {
      return;
    }
    try {
      dispatch({
        type: actions.LOADING,
        payload: {loading: true}
      });
      const orders = await searchOrders(term);
      console.log(' orders ..... ', orders);
      dispatch({
        type: actions.ORDERS_LIST,
        payload: {orders}
      });
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: {message: error}
      });
    }
  },
  getDealersList: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: {loading: true}
      });
      const dealers = await getAllDealers();
      dispatch({
        type: actions.DEALERS_LIST,
        payload: {dealers}
      });
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: {message: error}
      });
    }
  },
  getUsersList: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: {loading: true}
      });
      const users = await getAllUsers();
      dispatch({
        type: actions.USERS_LIST,
        payload: {users}
      });
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: {message: error}
      });
    }
  },
  getBannersList: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: {loading: true}
      });
      const banners = await getAllBanners();
      dispatch({
        type: actions.BANNERS_LIST,
        payload: {banners}
      });
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: {message: error}
      });
    }
  },
  updateTrackingInfo: (documentId, trackingInfo, order) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        await updateTrackingInfo(documentId, trackingInfo, order);

        dispatch(actions.updateOrderStatus(documentId, 'closed'));

        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  updateOrder: (documentId, order) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        await updateOrder(documentId, order);

        dispatch({
          type: actions.LOADING,
          payload: {loading: false}
        });

        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  addUpdateBanner: (banner) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        await addUpdateDocument('banner', banner);

        dispatch(actions.getBannersList());

        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  deleteBanner: (documentId) => async (dispatch) => {
    dispatch({
      type: actions.LOADING,
      payload: {loading: true},
    });
    await deleteDocument('banner', documentId)
    dispatch(actions.getBannersList());
  },
  deleteUser: (documentId) => async (dispatch) => {
    dispatch({
      type: actions.LOADING,
      payload: {loading: true},
    });
    await deleteDocument('user', documentId)
    dispatch(actions.getUsersList());
  },
  updateDealerStatus: (documentId, status) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        await updateDealerStatus(documentId, status);
        dispatch(actions.getDealersList());
        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  updateUserPermission: (documentId, status) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        await updateUserStatus(documentId, status);
        dispatch(actions.getUsersList());
        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  updateDealer: (documentId, dealer) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        const res = await updateDealer(documentId, dealer);
        if(res && res.error){
          dispatch({
            type: actions.ALERT,
            payload: {alert: true, message: res.message}
          });
          dispatch({
            type: actions.LOADING,
            payload: {loading: false}
          });
        }
        else {
          dispatch(actions.getDealersList());
        }
        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  addUser: (user) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        const res = await addUser(user);
        if(res && res.error){
          dispatch({
            type: actions.ALERT,
            payload: {alert: true, message: res.message}
          });
          dispatch({
            type: actions.LOADING,
            payload: {loading: false}
          });
        }
        else {
          dispatch(actions.getUsersList());
        }
        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  updateOrderStatus: (documentId, status) => async (dispatch) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: {loading: true}
        });
        await updateOrderStatus(documentId, status);

        dispatch({
          type: actions.LOADING,
          payload: {loading: false}
        });
        resolve()
      } catch (error) {
        dispatch({
          type: actions.ERROR,
          payload: {message: error}
        });
        reject(error)
      }
    });
  },
  listenUpdates: () => async (dispatch) => {
    updateListener(dispatch)
  }
};

export default actions;
