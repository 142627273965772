import { createSelector } from 'reselect';

const selectApp = (state) => state.app;
const selectOrders = createSelector(
  [selectApp],
  (app) => app.orders,
);
const selectStocks = createSelector(
  [selectApp],
  (app) => app.stocks,
);
const selectLoading = createSelector(
  [selectApp],
  (app) => app.loading,
);
const selectAlert = createSelector(
  [selectApp],
  (app) => app.alert,
);
const selectDealers = createSelector(
  [selectApp],
  (app) => app.dealers,
);
const selectUsers = createSelector(
  [selectApp],
  (app) => app.users,
);
const selectAlertMessage = createSelector(
  [selectApp],
  (app) => app.alertMessage,
);
const selectBanners = createSelector(
  [selectApp],
  (app) => app.banners,
);
const selectors = {
  selectApp,
  selectOrders,
  selectDealers,
  selectLoading,
  selectStocks,
  selectAlert,
  selectAlertMessage,
  selectBanners,
  selectUsers
};
export default selectors;
