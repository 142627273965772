import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import {Typography} from "antd";
import Grid from "@material-ui/core/Grid";
import * as Yup from 'yup';
import {Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {useDispatch} from 'react-redux';
import Tracking from "./Tracking";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

const {Title, Text} = Typography;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight:'80vh'
    },
    cellLabel: {
      textAlign: 'left',
      display: 'inline',
    },
  }),
);

export default function OrderDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [tracking, openTracking] = useState(false);
  const [page, setPage] = useState(0);
  useEffect(() => {
    setOrder(props.order)
  }, [props.order])
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <Grid
      xs={10} md={8} lg={6}
      container
      justify={'center'}
      style={modalStyle}
      className={classes.paper}>
      <Grid xs={12} className={'vert-flex'}>
        <h2 id="simple-modal-title">{'Order History'}</h2>

        <Table size="small" style={{marginTop: 30}}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order['history'] && order['history'].slice(page * 5, (page * 5) + 5).map((row, index) => (
              <TableRow key={row.timestamp}>
                <TableCell>{moment(row.timestamp).format('DD MMM, yyyy hh:mm a')}</TableCell>
                <TableCell>{row.event}</TableCell>
                <TableCell>{row.by}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={12}
                count={order['history'] ? order['history'].length : 0}
                rowsPerPage={5}
                rowsPerPageOptions={[]}
                page={page}
                onChangePage={(e, newPage) => setPage(newPage)}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <div style={{float: 'right', marginTop: 20}}>
          <Button
            onClick={() => props.onClose()}
            variant="contained"
            style={{marginRight: 10}}>
            Close
          </Button>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => {
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Tracking
        open={tracking}
        onClose={() => openTracking(false)}
        order={order}
        onUpdate={(order) => {
          setOrder(order)
        }}
      />
    </div>
  );
}