import React, {useCallback, useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import {Space, Typography} from "antd";
import {useDropzone} from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import {UploadOutlined} from '@ant-design/icons';
import TextField from "@material-ui/core/TextField";
import {useDispatch} from 'react-redux';
import actions from './../modules/appActions';
import {firebase} from "../firebase";
import Config from './../config/app';

const {Title, Text} = Typography;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    cellValue: {
      fontSize: 14,
      color: '#23120b',
      textAlign: 'left',
    },
  }),
);

export default function AddBanner(props) {
  console.log(' props ', props);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [collection, setCollection] = useState({image: 'https://firebasestorage.googleapis.com/v0/b/stockwall-local.appspot.com/o/collection%2F0.adlu9odv1bg.jpg?alt=media'});
  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    const name = Math.random().toString(36) + '.' + file.name.split('.').pop();
    const ref = firebase.storage().ref().child(`collection/${name}`);

    // [START storage_upload_blob]
    // 'file' comes from the Blob or File API
    ref.put(file).then((snapshot) => {
      console.log('Uploaded a blob or file!', name);
      const _collection = {...collection};
      _collection.image = `https://firebasestorage.googleapis.com/v0/b/${Config.firebaseConfig.storageBucket}/o/collection%2F${name}?alt=media`;
      setCollection(_collection)
    });
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  useEffect(() => {
    setCollection(props.collection)
  }, [props.collection])
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <Grid container justify={'center'} xs={12} sm={8} md={6} style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{'Add Banner'}</h2>
      <Grid container justify={'center'}>
        <Grid container xs={12} justify={'center'}>
          <div style={{border: 'lightgrey solid 1px', height: 100}}  {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="space-align-container">
              <div className="space-align-block">
                <Space align="center" style={{display: 'flex', justifyContent: 'space-between', padding: 30}}>
                  {
                    isDragActive ?
                      <span>Drop the file here ...</span> :
                      <span>Drag 'n' drop file here or </span>
                  }
                  <Button variant={'outlined'}>
                    <UploadOutlined/> Click to Upload
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} sm={5}>
            <TextField
              value={collection.name}
              onChange={(e) => {
                const _collection = {...collection};
                _collection.name = e.target.value
                setCollection(_collection);
              }}
              style={{marginTop: 40, marginLeft: 20}} fullWidth label={'Collection name'}/>
          </Grid>
          <Grid container xs={12} sm={5}>
            <TextField
              value={collection.youtubeid}
              onChange={(e) => {
                const _collection = {...collection};
                _collection.youtubeid = e.target.value
                setCollection(_collection);
              }}
              style={{marginTop: 40, marginLeft: 20}} fullWidth label={'Youtube Video Id'}/>
          </Grid>
        </Grid>
        <Grid container xs={8} sm={6}>
          {collection.image && <img style={{width: '100%', height: '100%', marginTop: 20}} src={collection.image}/>}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={{float: 'right', marginTop: 20}}>
          <Button
            onClick={() => props.onClose()}
            variant="contained"
            style={{marginRight: 10}}>
            Cancel
          </Button>
          <Button
            disabled={!collection.image || !collection.name}
            onClick={() => {
              dispatch(actions.addUpdateBanner(collection)).then(() => {
                props.onClose()
              }, () => {
              })
            }}
            variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}